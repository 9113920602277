import * as Yup from "yup";
import * as dayjs from "dayjs";
import { toast } from "react-toastify";
import { useModal } from "../../contexts/ModalContext";
import FormDefault from "../../components/Forms/FormDefault";
import { requestAuth } from "../../components/services/RequestService";
import CallServiceTable from "../../components/Tables/CallServiceTable";
import { filtersDefault } from "../../utils/constant";

const DimPayment = ({ type, procedureTypeId }) => {
  const { openModal, onClose } = useModal();
  function handleRegularization(procedure, reload) {
    openModal(
      <FormRegularization
        procedureTypeId={procedureTypeId}
        procedure={procedure}
        reload={reload}
        onClose={onClose}
      />
    );
  }
  return (
    <CallServiceTable
      //   filters={filtersDefault}
      urlApi={`/Logadex/DimPayment/Pag`}
      header={[
        {
          name: "internCode",
          label: "Nro. interno",
          filter: true,
        },
        {
          name: "initialDimPayment",
          label: "Días restantes",
          type: "custom",
          builder: (value, _, data) => {
            if (value) {
              const hour = dayjs().diff(dayjs(value), "hour");
              return (
                <div
                  className={`text-black ${
                    Number(hour) > 24
                      ? "bg-[#fd383863]"
                      : Number(hour) <= 24
                      ? "bg-[#4fce4421] text-gray-500"
                      : ""
                  } text-center rounded-full w-fit py-1 px-4`}
                >{`${dayjs(value).format(
                  "DD-MM-YYYY HH:mm"
                )} (${hour} horas)`}</div>
              );
            }
            return value;
          },
        },
        { name: "dimNumber", label: "Nro. DIM/DEX" },
        { name: "companyName", label: "Cliente" },
        { name: "stepDetail", label: "Paso Actual" },
        { name: "liquidadorAsignado", label: "Liquidador" },
        // {
        //   name: "currentStepName",
        //   label: "Paso Actual",
        //   type: "custom",
        //   builder: (value, _, data) => {
        //     if (data.step > data.totalSteps) {
        //       return "Terminado";
        //     }
        //     return value;
        //   },
        // },
        // {
        //   name: "currentStep",
        //   label: "Progreso",
        //   type: "custom",
        //   builder: (value, _, data) => {
        //     if (data.step > data.totalSteps) {
        //       return "Terminado";
        //     }
        //     return value;
        //   },
        // },
        // {
        //   name: "diasAbandono",
        //   label: "Días restantes abandono",
        //   type: "custom",
        //   builder: (value, _, head) => {
        //     const number = parseInt(head.timeDiasAbandono.split(".")[0]);
        //     return (
        //       <div
        //         className={`text-white ${
        //           number <= 10
        //             ? "bg-[#fd3838A1]"
        //             : number <= 25
        //             ? "bg-[#f3e40c51] text-[#747474]"
        //             : number <= 45
        //             ? "bg-[#4fce4421] text-gray-500"
        //             : "text-gray-500"
        //         } text-center rounded-full w-fit py-1 px-4`}
        //       >
        //         {value}
        //       </div>
        //     );
        //   },
        // },
        // {
        //   name: "estadoRegularizacion",
        //   label: "Estado",
        //   type: "custom",
        //   builder: (value) => {
        //     return (
        //       <div
        //         className={`text-white ${
        //           value === "Sin regularizar" || value === "Pendiente"
        //             ? "bg-[#fd3838A1]"
        //             : value === "Regularizado"
        //             ? "bg-[#4fce4421] text-gray-500"
        //             : ""
        //         } text-center rounded-full w-fit py-1 px-4 `}
        //       >
        //         {value}
        //       </div>
        //     );
        //   },
        // },
        // ...headerListContent,
      ]}
    />
  );
};

const FormRegularization = (props) => {
  const { procedure, procedureTypeId, reload, onClose } = props;

  async function uploadFile(values) {
    let formData = new FormData();
    formData.append("Name", "DIM Regularizada");
    formData.append("File", values.file);
    formData.append("originalName", values.file.name);
    formData.append("Status", "verified");
    formData.append("ProcedureId", procedure.id);
    await requestAuth("post", "/files", formData)
      .then((res) => {
        onSubmit({
          ...values,
          file: res.data.url,
          FileId: res.data.id,
        });
      })
      .catch(() => {
        toast.error("Se produjo un error al subir el archivo");
      });
  }

  async function onSubmit(values) {
    await requestAuth("post", "/dataset", [
      {
        FieldId: procedureTypeId === 1 ? 181 : 595,
        FileId: values.FileId,
        ProcedureId: procedure.id,
        Value: values.file,
      },
      {
        FieldId: procedureTypeId === 1 ? 594 : 596,
        ProcedureId: procedure.id,
        Value: values.observation,
      },
    ])
      .then(() => {
        reload();
        onClose();
        toast.success("Tramite regularizado");
      })
      .catch(() => {
        toast.error("Se ha producido un error");
      });
  }
  return (
    <FormDefault
      tittle={`Regularizar el trámite (${
        procedure.internCode || procedure.name
      })`}
      fields={[
        {
          label:
            procedureTypeId === 1
              ? "DIM regularizada"
              : "Constancia de salida de exportación",
          name: "file",
          type: "file",
          typeInput: "file",
        },
        {
          label:
            procedureTypeId === 1
              ? "Observaciones de la DIM regularizada"
              : "Observaciones de la constancia",
          name: "observation",
          placeholder: "Introduzca las observaciones",
          type: "textArea",
          typeInput: "input",
        },
      ]}
      initialValues={{
        file:
          procedureTypeId === 1
            ? procedure?.dimRegularizada && procedure.dimRegularizada !== "-"
              ? procedure.dimRegularizada
              : null
            : procedure?.constanciaDeSalidaDeExportacion &&
              procedure.constanciaDeSalidaDeExportacion !== "-"
            ? procedure.constanciaDeSalidaDeExportacion
            : null,
        observation:
          procedureTypeId === 1
            ? procedure?.observacionesDeLaDimRegularizada ?? ""
            : procedure?.observacionesDeLaConstanciaDeSalidaDeExportacion ?? "",
      }}
      validationSchema={Yup.object().shape({
        file: Yup.mixed().required("Campo requerido.").nullable(),
      })}
      onSubmit={(values) =>
        typeof values.dimRegularizada === "string"
          ? onSubmit(values)
          : uploadFile(values)
      }
      buttonName="Regularizar"
    />
  );
};

export default DimPayment;
