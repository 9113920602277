import * as Yup from "yup";
import FormDefault from "../../components/Forms/FormDefault";
import { toast } from "react-toastify";
import { Anchor, H3 } from "../../components/Text";
import { requestAuth } from "../../components/services/RequestService";

export const ExportCustomsExcel = ({ openDialog }) => {
  async function onSubmit(values) {
    await requestAuth(
      "post",
      `/logadex/deliveryReceiptDoc?${values.year ? `year=${values.year}` : ""}${
        values.companyId ? `&companyId=${values.companyId}` : ""
      }`
    )
      .then((res) => {
        toast.success("Registro exitoso");
        viewLinkExcel(res.data);
      })
      .catch(() => {
        toast.error("Se produjo un error al guardar");
      });
  }

  function viewLinkExcel(data) {
    openDialog(
      <div className="w-[300px]">
        <H3>Archivo generado</H3>
        <center>
          <Anchor
            className="text-[#264fbe] text-[1.4rem]"
            href={data.url}
            download
          >
            Descargar archivo
          </Anchor>
        </center>
        <br />
      </div>
    );
  }

  return (
    <div>
      <FormDefault
        tittle="Reporte para presentar a aduanas"
        fields={[
          {
            label: "Gestión",
            name: "year",
            type: "select",
            typeInput: "select",
            options: [
              {
                label: "2024",
                value: "2024",
              },
              {
                label: "2025",
                value: "2025",
              },
            ],
          },
          {
            label: "Compañia",
            name: "companyId",
            typeInput: "select",
            urlApi: "/company/getallcompanies",
            value: "id",
            labelOption: "razonSocial",
          },
        ]}
        initialValues={{
          year: "2024",
        }}
        validationSchema={Yup.object().shape({
          year: Yup.string().required("Campo requerido."),
        })}
        onSubmit={onSubmit}
        buttonName="Generar"
      />
      <br />
    </div>
  );
};
