import "./Header.css";
import ViewNotification from "./ViewNotification";
import UserInformation from "./UserInformation";
import { BoxFlex } from "../../components/Boxes";
import { useAuth } from "../../contexts/AuthContext";
import { Link, NavLink, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import Circle from "../../components/Animation/Circle";
import { Request } from "../../hooks/Request";
import ModalFullScreen from "../../components/Modal/ModalFullScreen";
import { H2 } from "../../components/Text";

const Header = (props) => {
  const { menu, setMenu } = props;
  return (
    <div className="h-[110px] flex flex-col justify-between bg-[#618BCC] sm:bg-gradient-to-r from-[#618BCC] to-[#0B3C5D]">
      <div className="flex justify-between items-center">
        <button
          onClick={() => setMenu(!menu)}
          className="hidden text-2xl px-4 portrait:block"
        >
          <i className="fas fa-angle-double-right"></i>
        </button>
        <ViewRegularization />
        <div className=""></div>
        <div className="flex justify-between items-center gap-2 px-4 pt-2">
          <ViewNotification />
          <UserInformation />
        </div>
      </div>
      <TabsContainer />
    </div>
  );
};

const ViewRegularization = () => {
  const { rolId } = useParams();
  const { data, loading } = Request({
    urlApi: "/Bruseco/toRegularize",
  });
  const [modal, setmodal] = useState(true);

  const { data: dataDim, loading: loadingDim } = Request({
    urlApi: "/Logadex/DimPayment",
  });

  // useEffect(() => {
  //   if (condition) {

  //   }
  //   openDialog();
  // }, [data?.regularized, dataDim]);

  return loading && loadingDim ? (
    <>
      <div className="flex gap-2 text-xl">
        <div
          className={`${
            data?.regularized ? "bg-red-500" : "bg-gray-500"
          } text-white px-2 py-1 mt-2 rounded-full`}
        >
          <Link to={`/rol/${rolId}/menu/regularization-anticipado`}>
            Anticipado: {data?.regularized ? data.regularized : "0"}
          </Link>
        </div>
        <div
          className={`${
            dataDim ? "bg-orange-600" : "bg-gray-500"
          } text-white px-2 py-1 mt-2 rounded-full`}
        >
          <Link to={`/rol/${rolId}/menu/dim-payment`}>
            Pago DIM: {dataDim ? dataDim : "0"}
          </Link>
        </div>
      </div>
      <ModalFullScreen onClose={() => setmodal(false)} activateForm={modal}>
        <H2>Alertas</H2>
        <Link to={`/rol/${rolId}/menu/regularization-anticipado`}>
          <h3>
            <strong className="font-semibold">Anticipado:</strong>{" "}
            {data?.regularized ? data.regularized : "0"}
          </h3>
        </Link>
        <Link to={`/rol/${rolId}/menu/dim-payment`}>
          <h3>
            <strong className="font-semibold">Pago DIM:</strong>{" "}
            {dataDim ? dataDim : "0"}
          </h3>
        </Link>
        <br />
      </ModalFullScreen>
    </>
  ) : (
    <Circle />
  );
};

const TabsContainer = () => {
  const { user, setUser } = useAuth();
  const { rolId } = useParams();
  useEffect(() => {
    setUser(user);
  }, []);

  return (
    <div className="">
      <nav className="flex overflow-auto">
        {user?.rols?.map((rol) => {
          const isActive = parseInt(rolId) === rol.id;
          return (
            <NavLink
              to={`/rol/${rol.id}/menu`}
              key={rol.id}
              className={
                isActive
                  ? "drop-shadow-md duration-200 cursor-pointer font-semibold bg-white rounded-t-[15px] py-[5px] px-[10px] flex w-auto items-center justify-center  text-lg lg:text-xl text-black"
                  : "drop-shadow-md duration-200 cursor-pointer bg-[#2F527B] shadow-[0_0_4px_rgba(0,0,0,0.25)] rounded-t-[15px] py-[5px] px-[5px] sm:px-[10px] flex w-auto items-center justify-center text-lg lg:text-xl text-[white]"
              }
            >
              <FontAwesomeIcon
                icon={`${rol.icon ? rol.icon : "fa-solid fa-user-pen"}`}
                className={`'mr-1'${
                  isActive ? "fill-black mr-4" : "fill-[#ffffff] mr-1"
                }`}
              />
              <span className="min-w-fit">{rol.label}</span>
            </NavLink>
          );
        })}
      </nav>
    </div>
  );
};

export default Header;
