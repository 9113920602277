import React, { useState } from "react";
import CallServiceTable from "../../components/Tables/CallServiceTable";
import TableComplete from "../../components/Tables/TableComplete";
import { toast } from "react-toastify";
import FormDefault from "../../components/Forms/FormDefault";
import * as Yup from "yup";
import { requestAuth } from "../../components/services/RequestService";
import SubModal from "../../components/Modal/SubModal";
import {
  adjuntarDex,
  readDam,
  readDim,
  readParte,
} from "../../components/services/ReadFile";
import { H1, H3, Anchor } from "../../components/Text";
import { useDialog } from "../../contexts/DialogContext";
import { useModal } from "../../contexts/ModalContext";
import { Button } from "../../components/Buttons";
import { DialogConfirmation } from "../../components/Modal";
import { addFiltersDefault, validateType } from "../../utils/constant";
import { Request } from "../../hooks/Request";
import RenderPdf from "../../containers/ManegeProcedure/RenderPdf";
import GenerateRoadmap from "../../containers/ManegeProcedure/GenerateRoadmap";
import RenderLiquidaciónProforma from "../../containers/ManegeProcedure/RenderLiquidaciónProforma";
import GenerateFileDelivery from "../../containers/ManegeProcedure/GenerateFileDelivery";
import RenderSettlement from "../../containers/ManegeProcedure/RenderSettlement";
import FormReadPdf from "../../containers/ManegeProcedure/FormReadPdf";
import GenerateReceipt from "../../containers/ManegeProcedure/GenerateReceipt";
import ViewFormStatus from "../../containers/ManegeProcedure/ViewFormStatus";
import GenerateTransportReceipt from "../../containers/ManegeProcedure/GenerateTransportReceipt";
import {
  validateStatus,
  validateArray,
  generateYearArray,
} from "../../utils/validate";
import { Tooltip } from "react-tippy";
import { useAuth } from "../../contexts/AuthContext";
import { headerList, filtersDefault } from "../../utils/constant";
import ListDocument from "../../components/common/ListDocument";
import { useNavigate, useParams } from "react-router-dom";
import ListFields from "../../containers/Systems/ListFields";
import RenderSelectLogo from "../../containers/ManegeProcedure/RenderSelectLogo";
import PdfProform from "../../containers/ManegeProcedure/PdfProform";
import PdfLiquidation from "../../containers/ManegeProcedure/PdfLiquidation";

const ManegeProcedure = () => {
  const [dataCampo, setdataCountryside] = useState();
  const [dataProcedimiento, setdataProcedure] = useState();
  const [formCountryside, setFormCountryside] = useState();
  const [formProcedure, setformProcedure] = useState();
  const [reload, setReload] = useState(false);
  const [dataForms, setdataForms] = useState(null);
  const [formFroms, setFormFroms] = useState(false);
  const { user } = useAuth();
  const navigate = useNavigate();
  const params = useParams();

  const { openDialog, dialogClose } = useDialog();
  const { openModal, onClose } = useModal();

  function editCountryside(data) {
    setdataCountryside(data);
    setFormCountryside(true);
  }
  function editProcedure(data) {
    setdataProcedure(data);
    setformProcedure(true);
  }
  function editForm(data) {
    setdataForms(data);
    setFormFroms(true);
  }

  function handleOpen(procedure, field, value) {
    if (field.type === "subDataAdd" || field.type === "subData") {
      openDialog(
        <div className="max-w-[600px]">
          <ViewSubData
            procedure={procedure}
            field={field}
            value={value}
            user={user}
          />
        </div>
      );
    } else {
      openDialog(
        <ViewDataset
          procedure={procedure}
          field={field}
          value={value}
          user={user}
        />
      );
    }
  }

  function handleBuilder(value, _, data) {
    if (data.type === "file" || data.type === "fileRead") {
      return (
        <Anchor
          className="underline text-blue-700 hover:decoration-1 hover:text-blue-500"
          href={value}
          target="_blank"
        >
          Abrir enlace
        </Anchor>
      );
    } else if (
      data.type === "controlField" ||
      data.type === "fileStatus" ||
      data.type === "subDataAdd" ||
      data.type === "subData"
    ) {
      return (
        <div
          onClick={() => handleOpen(dataProcedimiento, data, value)}
          className="flex gap-2 hover:text-blue-700 cursor-pointer"
        >
          <div className="">
            {value} {data.type === "fileStatus" ? "Abrir" : ""}{" "}
          </div>
          <i className="fas fa-external-link-alt"></i>
        </div>
      );
    }
    return value ?? "-";
  }

  function listDocumentPdf(procedure) {
    openDialog(<RenderPdf procedure={procedure} />);
  }
  function generateSettlementProforma(procedure) {
    openModal(<RenderLiquidaciónProforma procedure={procedure} />);
  }
  function generateSettlement(procedure) {
    openModal(<RenderSettlement procedure={procedure} />);
  }
  function SelectLogoElement(procedure) {
    openModal(<RenderSelectLogo procedure={procedure} typeReceipt="normal" />);
  }
  function SelectLogoTransportElement(procedure) {
    openModal(
      <RenderSelectLogo procedure={procedure} typeReceipt="transporte" />
    );
  }

  function handleDocument(procedure) {
    openModal(<ListDocument procedure={procedure} />);
  }
  // function generateSettlementProforma(procedure) {
  //   openDialog(<RenderLiquidaciónProforma procedure={procedure} />);
  // }

  // function generateProforma(procedure) {
  //   openDialog(<RenderProforma procedure={procedure} />);
  // }

  function handleDocument(procedure) {
    openModal(
      <ListDocument
        addDocument={true}
        procedure={procedure}
        openModal={openModal}
        onClose={onClose}
        openDialog={openDialog}
        dialogClose={dialogClose}
      />
    );
  }

  function generateRoadmap(procedure) {
    openDialog(<GenerateRoadmap procedure={procedure} />);
  }

  function generateReceipt(procedure) {
    openDialog(<GenerateReceipt procedure={procedure} />);
  }

  function generateTransportReceipt(procedure) {
    openDialog(<GenerateTransportReceipt procedure={procedure} />);
  }

  function generateFileDelivery(procedure) {
    openModal(
      <GenerateFileDelivery
        procedure={procedure}
        openModal={openModal}
        onClose={onClose}
        openDialog={openDialog}
        dialogClose={dialogClose}
      />
    );
  }

  function generateItems() {
    openDialog(
      <DialogConfirmation
        children={
          <H3>¿Estas seguro que quieres generar el reporte de los tramites?</H3>
        }
        onClose={dialogClose}
        method="put"
        url="/files/generalFormat"
        texBtn="Generar"
        handleSuccess={viewLinkExcel}
      />
    );
  }
  function generateArchive() {
    openDialog(
      <DialogConfirmation
        children={
          <H3>¿Estas seguro que quieres generar el reporte de archivo?</H3>
        }
        onClose={dialogClose}
        method="put"
        url="/files/archiveFormat"
        texBtn="Generar"
        handleSuccess={viewLinkExcel}
      />
    );
  }

  function viewLinkExcel(data) {
    openDialog(
      <div className="w-[300px]">
        <H3>Archivo generado</H3>
        <center>
          <Anchor
            className="text-[#264fbe] text-[1.4rem]"
            href={data.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            Descargar archivo
          </Anchor>
        </center>
        <br />
      </div>
    );
  }

  function handleDelete(procedure, reload) {
    openDialog(
      <DialogConfirmation
        children={<H3>¿Estas seguro que quieres eliminar el tramite?</H3>}
        onClose={dialogClose}
        method="delete"
        url={`/procedure/${procedure.id}`}
        texBtn="Eliminar"
        handleSuccess={reload}
      />
    );
  }

  function handleReadPdf() {
    openModal(<FormReadPdf />);
  }

  function handleRedirect(procedure) {
    navigate(
      `/rol/${params.rolId}/procedure-type/${procedure.procedureTypeId}/step/${procedure.step}`
    );
  }

  function handleStatus(procedure, reload) {
    openModal(
      <ViewFormStatus
        reload={reload}
        procedure={procedure}
        openModal={openModal}
        onClose={onClose}
        openDialog={openDialog}
        dialogClose={dialogClose}
      />
    );
  }

  function handleProform(procedure) {
    openModal(<PdfProform procedure={procedure} />);
  }
  function handleLiquidation(procedure) {
    openDialog(<PdfLiquidation procedure={procedure} />);
  }

  // function readDocument() {
  //   openModal(<FormReadDocument />);
  // }

  return (
    <>
      <CallServiceTable
        reload={reload}
        component={({ reload }) => {
          return (
            <>
              {/* <Button
                position="left"
                content="Listar Documentos"
                onClick={() => generateDocuments(reload)}
              >
                <i className="fas fa-file-contract"></i>
              </Button>  */}
              <Button
                position="left"
                content="Generar reporte"
                onClick={() => generateItems(reload)}
              >
                <i className="fas fa-book"></i>
                {/* <i className="fas fa-file-excel"></i> */}
              </Button>
              <Button
                position="left"
                content="Generar reporte de archivo"
                onClick={() => generateArchive(reload)}
              >
                <i className="fas fa-archive"></i>
              </Button>
              {/* <Button
                position="left"
                content="Leer documentos de la DIM"
                onClick={() => readDocument(reload)}
              >
                <i className="fas fa-archive"></i>
              </Button> */}
            </>
          );
        }}
        addFilters={addFiltersDefault}
        urlApi="/procedure/openprocedures"
        downloadUrl="/tradecruz/openprocedures"
        download={true}
        filters={[
          {
            name: "procedureType",
            // url: "/procedureType",
            default: "",
            defaultValue: "Importación",
            // valueOption: "id",
            // labelOption: "name",
            label: "Tipo de Trámite",
            options: [
              {
                label: "Importación",
                name: "1",
                initial: true,
              },
              {
                label: "Exportación",
                name: "2",
              },
            ],
          },
          {
            name: "Year",
            default: "",
            label: "Todas las gestiones",
            options: generateYearArray(),
          },
          ...filtersDefault,
        ]}
        header={[
          ...headerList,
          // {
          //   name: "Reports",
          //   type: "action",
          //   label: "Reportes",
          //   actions: [
          //     {
          //       label: "Generar informe de la recepción de documentos",
          //       icon: "fas fa-tasks",
          //       action: listDocumentPdf,
          //       color: "text-[#1d4ed8] pl-3",
          //     },
          //   ],
          // },
          {
            name: "manage",
            type: "action",
            label: "Gestionar",
            sticky: true,
            actions: [
              // {
              //   label: "Redirigir al paso",
              //   icon: "fas fa-reply",
              //   action: handleRedirect,
              //   color: "text-[#ff2d55]",
              // },
              {
                label: "Generar proforma",
                icon: "fas fa-coins",
                action: handleProform,
                color: "text-[#1d4ed8]",
              },
              // {
              //   label: "Generar proforma",
              //   icon: "fas fa-coins",
              //   action: generateSettlementProforma,
              //   color: "text-[#1d4ed8]",
              // },
              {
                label: "Generar liquidación",
                icon: "fas fa-file-invoice",
                action: handleLiquidation,
                color: "text-[#1d4ed8]",
              },

              // {
              //   label: "Generar liquidación",
              //   icon: "fas fa-money-bill",
              //   action: generateSettlement,
              //   color: "text-[#1d4ed8]",
              // },
              {
                label: "Ver documentos",
                icon: "fa-solid fa-folder",
                action: handleDocument,
                color: "text-[#1d4ed8]",
              },
              // {
              //   label: "Generar entrega de documentos",
              //   icon: "fas fa-file",
              //   action: generateFileDelivery,
              //   color: "text-[#1d4ed8]",
              // },
              {
                label: "Generar hoja de ruta",
                icon: "fas fa-route",
                action: generateRoadmap,
                color: "text-[#1d4ed8] pr-5",
              },
              {
                label: "Editar valores",
                icon: "fas fa-th-list",
                action: editProcedure,
                color: "text-[#1d4ed8]",
              },
              // {
              //   label: "Generar recibo",
              //   icon: "fas fa-receipt",
              //   name: "trz-013-generarRecibo",
              //   action: SelectLogoElement,
              //   color: "text-[#1d4ed8] pr-5",
              // },
              // {
              //   label: "Generar recibo de transporte",
              //   icon: "fas fa-shuttle-van",
              //   action: SelectLogoTransportElement,
              //   color: "text-[#1d4ed8] pr-5",
              // },
              {
                label: "Editar trámite",
                icon: "fas fa-edit",
                action: editCountryside,
                color: "text-[#1d4ed8]",
              },
              // {
              //   label: "Editar tarea",
              //   icon: "fas fa-stream",
              //   action: handleStatus,
              //   color: "text-[#1d4ed8]",
              // },
              {
                label: "Eliminar tramite",
                icon: "fas fa-trash",
                action: handleDelete,
                color: "text-[#ff2d55]",
              },
            ],
          },
        ]}
      />

      <SubModal
        setactivateForm={setFormCountryside}
        activateForm={formCountryside}
      >
        <FormDefault
          tittle="Editar trámite"
          fields={[
            {
              label: "Seleccione la compañía",
              name: "CompanyId",
              aditionalName: "CompanyName",
              typeInput: "select",
              urlApi: "/company/getallcompanies",
              value: "id",
              labelOption: "razonSocial",
            },
            {
              label: "Seleccione el paso:",
              name: "Step",
              aditionalName: "StepName",
              typeInput: "select",
              urlApi: `/processstep/GetProcessStepsByProcedureTypeId/${
                dataCampo?.procedureTypeId ? dataCampo.procedureTypeId : ""
              }`,
              value: "step",
              labelOption: "name",
              labelOptionParam: "step",
            },
            {
              label: "Descripción",
              name: "Description",
            },
          ]}
          initialValues={{
            id: dataCampo?.id,
            ProcedureTypeId: dataCampo?.procedureTypeId,
            Step: dataCampo?.step,
            CompanyId: dataCampo?.companyId + "",
            StepName:
              dataCampo?.step +
              (dataCampo?.currentStepName
                ? ". " + dataCampo?.currentStepName
                : ""),
            CompanyName: dataCampo?.companyName || dataCampo?.companyId + "",
          }}
          validationSchema={Yup.object().shape({
            id: Yup.string().required("Este campo es obligatorio"),
            CompanyId: Yup.string().required("Este campo es obligatorio"),
            ProcedureTypeId: Yup.string().required("Este campo es obligatorio"),
            Step: Yup.string().required("Este campo es obligatorio"),
            Description: Yup.string().required("Este campo es obligatorio"),
          })}
          onSubmit={(values, resetForm) => {
            requestAuth("put", "/procedure", values)
              .then(() => {
                setFormCountryside(!formCountryside);
                setReload(!reload);
                toast.success("Envio exitoso");
                resetForm();
              })
              .catch(() => {
                toast.error("Error en el envio");
              });
          }}
          buttonName="Editar"
        />
      </SubModal>

      {formProcedure && (
        <SubModal
          setactivateForm={setformProcedure}
          activateForm={formProcedure}
        >
          <CallServiceTable
            reload={reload}
            urlApi={`/field/fieldsbyprocedureId/${dataProcedimiento?.id}`}
            filters={[
              {
                name: "step",
                url: `/processstep/GetProcessStepsByProcedureTypeId/${
                  dataProcedimiento?.procedureTypeId
                    ? dataProcedimiento.procedureTypeId
                    : ""
                }`,
                label: "Todos los pasos",
                valueOption: "step",
                labelOption: "name",
              },
            ]}
            header={[
              {
                name: "label",
                label: "Nombre",
                filter: true,
              },
              {
                name: "initial",
                label: "Valor",
                filter: false,
                builder: handleBuilder,
                type: "custom",
              },
              {
                name: "editValues",
                type: "action",
                label: "Editar",
                actions: [
                  {
                    label: "Editar valor",
                    icon: "fas fa-file-pen",
                    action: editForm,
                    color: "text-[#1d4ed8]",
                  },
                ],
              },
            ]}
            addUrl={`OrderBy=label`}
            statusPaination={true}
            showTable={false}
            edit={true}
            responsive={true}
          />
        </SubModal>
      )}
      {dataForms && (
        <EditDataset
          dataForms={dataForms}
          setactivateForm={setFormFroms}
          activateForm={formFroms}
          dataProcedimiento={dataProcedimiento}
          setReload={setReload}
          reload={reload}
        />
      )}
    </>
  );
};

const ViewSubData = (props) => {
  const { procedure, field, value, user } = props;

  const { data, loading, call } = Request({
    urlApi: `/dataSet/procedure/${procedure.id}/field/${field.id}/subdata`,
  });

  return (
    <div>
      {!loading ? null : (
        <div className="w-full">
          <TableComplete
            header={[
              {
                name: "label",
                label: "Campo",
              },
              {
                name: "description",
                label: "Observación",
              },
              // {
              //   name: "add",
              //   type: "action",
              //   label: "Opciones",
              //   sticky: true,
              //   actions: [
              //     {
              //       label: "Editar",
              //       icon: "fas  fa-edit",
              //       action: handleForm,
              //       color: "text-[#1d4ed8]",
              //     },
              //     {
              //       label: "Añadir",
              //       icon: "fas fa-trash",
              //       action: handleDelete,
              //       color: "text-red-500",
              //     },
              //   ],
              // },
            ]}
            data={data}
          />
        </div>
      )}
    </div>
  );
};

const ViewDataset = (props) => {
  const { procedure, field, value, user } = props;
  const { data, loading } = Request({
    urlApi: `/fileState/procedure/${procedure.id}/field/${field.id}`,
  });
  const headFileStatus = [
    {
      name: "number",
      label: "Nro",
    },
    {
      name: "url",
      label: "Enlace",
      type: "custom",
      builder: (item) => {
        if (item) {
          return (
            <Anchor
              href={item}
              className="text-[#1d4ed8] underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              Abrir
            </Anchor>
          );
        } else {
          return "-";
        }
      },
    },
    {
      name: "creationDate",
      label: "Fecha de creación",
      type: "date",
    },
    {
      name: "presentation",
      label: "Presentación",
    },
    {
      name: "status",
      label: "Estado",
    },
    {
      label: "Proveedor/Emisor",
      name: "provider",
    },
  ];

  const headControlField = [
    {
      name: "url",
      label: "Enlace",
      type: "custom",
      builder: (item) => {
        if (item) {
          return (
            <Anchor
              href={item}
              className="text-[#1d4ed8] underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              Abrir
            </Anchor>
          );
        } else {
          return "-";
        }
      },
    },
    {
      name: "creationDate",
      label: "Fecha de creación",
      type: "date",
    },
    {
      name: "description",
      label: "Descripción",
    },
  ];

  if (!loading) {
    return null;
  }
  return (
    <div>
      <H1>{field.label}</H1>
      {field.type === "controlField" && (
        <H3 className="font-normal text-start mb-2">
          <strong>Descripción:</strong> {value}
        </H3>
      )}

      <TableComplete
        header={field.type === "fileStatus" ? headFileStatus : headControlField}
        data={data}
      />
      <br />
    </div>
  );
};

const EditDataset = (props) => {
  const {
    dataForms,
    activateForm,
    setactivateForm,
    dataProcedimiento,
    setReload,
    reload,
  } = props;
  const [load, setload] = useState(false);
  function checkSend(values, resetForm) {
    if (values.type === "file" || values.type === "fileRead") {
      let formData = new FormData();
      formData.append("Name", values.name);
      formData.append("File", values[values.name]);
      formData.append("originalName", values[values.name].name);
      formData.append("Status", "verified");
      formData.append("ProcedureId", dataProcedimiento.id);
      requestAuth("post", "/files", formData).then((res) => {
        onSubmit({ ...values, [values.name]: res.data.url }, resetForm);
      });
    } else {
      onSubmit(values, resetForm);
    }
  }
  function onSubmit(values, resetForm) {
    requestAuth(
      "put",
      `/dataset/editdataset/${values.ProcedureId}/fieldid/${values.FieldId}`,
      { ...values, Value: values[values.name] }
    )
      .then(() => {
        setactivateForm(!activateForm);
        setReload(!reload);
        toast.success("Datos Editados Correctamente");
        resetForm();
      })
      .catch(() => {
        toast.error("Error de Edicion");
      });
  }
  function handleRead() {
    console.log(dataForms);
    setload(true);
    switch (dataForms.url) {
      case "adjuntarDim":
        readDim([dataForms], 0, dataProcedimiento, [], handleFinishRead);
        break;
      case "dimRegularizada":
        readDim([dataForms], 0, dataProcedimiento, [], handleFinishRead);
        break;
      case "adjuntarDex":
        adjuntarDex([dataForms], 0, dataProcedimiento, [], handleFinishRead);
        break;
      case "documentoDam":
        readDam([dataForms], 0, dataProcedimiento, [], handleFinishRead);
        break;
      case "parteDeRecepcion":
        readParte([dataForms], 0, dataProcedimiento, [], handleFinishRead);
        break;
    }
  }
  function handleFinishRead(_, __, result) {
    setload(false);
    if (validateArray(result) && validateStatus(result[0].readStatus)) {
      setactivateForm(!activateForm);
      setReload(!reload);
      toast.success("Se extrajo los datos con éxito");
    } else {
      toast.error(result[0].readData);
    }
  }

  return (
    <div>
      <SubModal setactivateForm={setactivateForm} activateForm={activateForm}>
        <div className="flex">
          <H1 className="text-start">Editar valor</H1>
          {dataForms.initial && dataForms.type === "fileRead" ? (
            load ? (
              <div className="h-[80px] flex justify-center items-center">
                <svg className="animate-spin h-[30px] mr-3" viewBox="0 0 24 24">
                  <i className="text-white font-bold fa-solid fa-circle-notch"></i>
                </svg>
              </div>
            ) : (
              <Tooltip
                className="sm:mr-[20px] my-[10px]"
                title="Extraer datos del archivo"
                position="top"
                trigger="mouseenter"
              >
                <Button onClick={handleRead} width="w-auto">
                  <i className="fa-solid fa-file-export"></i>
                </Button>
              </Tooltip>
            )
          ) : null}
        </div>
        <FormDefault
          fields={[
            {
              id: dataProcedimiento.id,
              fieldId: dataForms?.id,
              label: dataForms?.label,
              name: dataForms?.name,
              placeholder: "Complete el campo",
              type: dataForms?.type === "fileRead" ? "file" : dataForms?.type,
              typeInput:
                dataForms?.type === "fileRead" ? "file" : dataForms?.type,
              initial: dataForms?.initial ? dataForms?.initial : null,
              // urlApi: dataForms?.url,
              urlApi: dataForms.url
                ? dataForms.url.includes("/")
                  ? dataForms.url
                  : `/Dropdown/${dataForms.url}/options`
                : "",
              urlInitial: dataForms.url,
              value: "name",
              labelOption: "name",
            },
            {
              label: "Descripción",
              name: "Description",
            },
          ]}
          initialValues={{
            ProcedureId: dataProcedimiento?.id,
            [dataForms?.name]: dataForms?.initial,
            FieldId: dataForms?.id,
            ...dataForms,
          }}
          validationSchema={Yup.object().shape({
            [dataForms?.name]: validateType[dataForms.type] || null,
            Description: Yup.string().required("Este campo es obligatorio"),
          })}
          onSubmit={checkSend}
          buttonName="Editar"
        />
      </SubModal>
    </div>
  );
};

export default ManegeProcedure;
