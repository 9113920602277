import * as Yup from "yup";
import FormDefault from "../../components/Forms/FormDefault";
import { toast } from "react-toastify";
import { Anchor, H3 } from "../../components/Text";
import { requestAuth } from "../../components/services/RequestService";

export const ExportProcedureExcel = ({ openDialog, companyId }) => {
  async function onSubmit(values) {
    await requestAuth(
      "post",
      `/logadex/contrav?${
        values.InitDate ? `InitDate=${values.InitDate}` : ""
      }${values.EndDate ? `&EndDate=${values.EndDate}` : ""}${
        values.Sucursal ? `&Sucursal=${values.Sucursal}` : ""
      }${values.CompanyId ? `&CompanyId=${values.CompanyId}` : ""}`
    )
      .then((res) => {
        toast.success("Registro exitoso");
        viewLinkExcel(res.data);
      })
      .catch(() => {
        toast.error("Se produjo un error al guardar");
      });
  }

  function viewLinkExcel(data) {
    openDialog(
      <div className="w-[300px]">
        <H3>Archivo generado</H3>
        <center>
          <Anchor
            className="text-[#264fbe] text-[1.4rem]"
            href={data.url}
            download
          >
            Descargar archivo
          </Anchor>
        </center>
        <br />
      </div>
    );
  }

  return (
    <div>
      <FormDefault
        tittle="Reporte de carpetas"
        fields={
          companyId
            ? [
                {
                  label: "Sucursal",
                  name: "Sucursal",
                  type: "select",
                  typeInput: "select",
                  options: [
                    {
                      label: "La Paz",
                      value: "LP",
                    },
                    {
                      label: "Santa Cruz",
                      value: "SC",
                    },
                  ],
                },
                {
                  name: "InitDate",
                  label: "Fecha de inicio",
                  type: "date",
                },
                {
                  name: "EndDate",
                  label: "Fecha fin",
                  type: "date",
                },
              ]
            : [
                {
                  label: "Sucursal",
                  name: "Sucursal",
                  type: "select",
                  typeInput: "select",
                  options: [
                    {
                      label: "La Paz",
                      value: "LP",
                    },
                    {
                      label: "Santa Cruz",
                      value: "SC",
                    },
                  ],
                },
                {
                  label: "Compañia",
                  name: "CompanyId",
                  typeInput: "select",
                  urlApi: "/company/getallcompanies",
                  value: "id",
                  labelOption: "razonSocial",
                },
                {
                  name: "InitDate",
                  label: "Fecha de inicio",
                  type: "date",
                },
                {
                  name: "EndDate",
                  label: "Fecha fin",
                  type: "date",
                },
              ]
        }
        initialValues={{
          Management: 2024,
          CompanyId: companyId ?? "",
        }}
        validationSchema={Yup.object().shape({
          Management: Yup.number().required("Campo requerido."),
        })}
        onSubmit={onSubmit}
        buttonName="Generar"
      />
      <br />
    </div>
  );
};
