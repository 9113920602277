// import { useEffect } from "react";
import { Page, Document, PDFViewer, Image } from "@react-pdf/renderer";
import * as dayjs from "dayjs";
import { Request } from "../../hooks/Request";
import { Circle } from "../../components/Animation";
import View from "../../components/GeneratePdf/View";
import Text from "../../components/GeneratePdf/Text";
import CustomTablePDF from "../../components/GeneratePdf/CustomTablePDF";
import logotipo from "../../assets/LogotipoTC.png";
import Logadex from "../../assets/Logadex.jpg";
import qrLogadex from "../../assets/qrLogadex.png";
import { validateArray } from "../../utils/validate";
import FormDefault from "../../components/Forms/FormDefault";
import { H2, H3 } from "../../components/Text";
import { useDialog } from "../../contexts/DialogContext";
import { requestAuth } from "../../components/services/RequestService";
import { toast } from "react-toastify";

const PdfProform = (props) => {
  const { procedure } = props;
  const { openDialog } = useDialog();
  const { data, loading } = Request({
    urlApi: `/procedure/${
      procedure.id || procedure.procedureId
    }/datasets?fieldnames=tipoDeCambioProforma
    &fieldnames=importacionN_º
    &fieldnames=atención
    &fieldnames=derechosYTributosDeImportación
    &fieldnames=detallesDeLaLiquidación`,
  });

  console.log("🚀 ~ PdfProform ~ data:", data);
  if (!loading) {
    return <Circle />;
  }

  async function onSubmit(values, resetForm) {
    const result = await Promise.all(
      data.map(async (element) => {
        if (element.type === "file" || element.type === "fileRead") {
          if (
            (values[element.name] === "" || values[element.name] === null) &&
            (element.initial === "" || element.initial === null)
          ) {
            return null;
          } else if (
            (values[element.name] !== "" && !element.initial) ||
            (values[element.name] !== "" &&
              values[element.name] !== element.initial)
          ) {
            let formData = new FormData();
            formData.append("Name", element.name);
            formData.append("File", values[element.name]);
            formData.append("originalName", values[element.name].name);
            formData.append("Status", "verified");
            formData.append("ProcedureId", procedure.id);
            const fileContents = await requestAuth(
              "post",
              "/files",
              formData
            ).then((res) => {
              return {
                Value: res.data.url,
                ProcedureId: procedure.id,
                FieldId: element.id,
                FileId: res.data.id,
                name: element.name,
                url: element.url,
                type: element.type,
              };
            });
            return fileContents;
          } else {
            return {
              Value: element.initial,
              ProcedureId: procedure.id,
              FieldId: element.id,
              name: element.name,
              url: element.url,
              type: element.type,
            };
          }
        } else if (element.type === "selectNewOption") {
          if (values[element.name + "_isNew"]) {
            await handleCreateOption(
              values[element.name] || values[element.name] === 0
                ? values[element.name]
                : "",
              element.url
            );
          }
          return {
            Value:
              values[element.name] || values[element.name] === 0
                ? values[element.name]
                : "",
            ProcedureId: procedure.id,
            FieldId: element.id,
            name: element.name,
            url: element.url,
            type: element.type,
          };
        } else {
          return {
            Value:
              values[element.name] || values[element.name] === 0
                ? values[element.name]
                : "",
            ProcedureId: procedure.id,
            FieldId: element.id,
            name: element.name,
            url: element.url,
            type: element.type,
          };
        }
      })
    );
    if (procedure.category === "multiple") {
    } else {
      await submitDataset(
        result.filter((n) => n),
        resetForm,
        values.btnSecondary
      );
    }
  }

  async function handleCreateOption(value, dropdown) {
    await requestAuth("post", "/dropdown/option", {
      Name: value,
      DropdownListLabel: dropdown,
    });
  }

  async function submitDataset(values, resetForm, btnSecondary) {
    await requestAuth("post", "/dataset", values)
      .then(async () => {
        // reload();
        // onClose();
        resetForm();
        openDialog(<PdfProformView procedure={procedure} />);
        toast.success("Envio exitoso");
      })
      .catch(() => {
        toast.error("Error en el envio");
      });
  }
  function dataIntialValues(list) {
    let aux = {};
    list?.forEach((element) => {
      aux = {
        ...aux,
        [element.name]:
          (element.initial === "false" ? false : element.initial) || "",
      };
    });
    return aux;
  }
  return (
    <div className="">
      <H2>Datos de la proforma</H2>
      {validateArray(data) ? (
        <FormDefault
          fields={data
            .sort((a, b) => a.order - b.order)
            .map((value, index) => ({
              ...value,
              id: procedure.id || procedure.procedureId,
              fieldId: value.id,
              labelCustom: (
                <>
                  <span className="font-bold text-green-600">{index + 1}.</span>{" "}
                  {value.label}
                </>
              ),
              label: value.label,
              labelCustom: (
                <>
                  <span className="text-green-600">{index + 1}.</span>{" "}
                  {value.label}
                </>
              ),
              name: value.name,
              validate: value.validate,
              lockEdition: value.lockEdition,
              placeholder: "Complete el campo",
              type: value.type === "fileRead" ? "file" : value.type,
              typeInput: value.type === "fileRead" ? "file" : value.type,
              initial: value.initial ? value.initial : null,
              urlApi: value.url
                ? value.url.includes("/")
                  ? value.url
                  : `/Dropdown/${value.url}/options`
                : "",
              urlInitial: value.url,
              value: "name",
              labelOption: "name",
              action: () => {},
              reload: () => {},
              step: 1,
            }))}
          initialValues={dataIntialValues(data)}
          validationSchema={null}
          onSubmit={async (values, resetForm) =>
            await onSubmit(values, resetForm, data)
          }
          buttonFixed={true}
          buttonSecondary={false}
          buttonName="Registrar"
        />
      ) : (
        <H3>NO SE TIENE NINGÚN FORMULARIO</H3>
      )}
    </div>
  );
};

const PdfProformView = (props) => {
  const { procedure } = props;
  const { data, loading } = Request({
    urlApi: `/procedure/${
      procedure.id || procedure.procedureId
    }/dataValues?fieldnames=detallesDeLaLiquidación&fieldnames=derechosYTributosDeImportación&fieldnames=codigoDeInterno&fieldnames=importacionN_º&fieldnames=atención&fieldnames=tipoDeCambioProforma`,
  });

  if (!loading) {
    return <Circle />;
  }

  const MyDoc = (
    <Document>
      <Page size="A4" style={{ padding: "20px" }}>
        <View justifyContent="space-between">
          <Image
            src={Logadex}
            style={{ width: "120px", paddingLeft: "20px" }}
          />
          <View flexDirection="col" alignItems="flex-end">
            <Text textAlign="center" fontSize="10px" bold>
              Cod. F-05-06
            </Text>
            <Text textAlign="center" fontSize="10px" bold>
              Rev.00
            </Text>
            <Text textAlign="center" fontSize="10px" bold>
              FECHA: {dayjs().format("D")} de {dayjs().format("MMMM")} de{" "}
              {dayjs().format("YYYY")}
            </Text>
            <Text textAlign="center" fontSize="10px" bold>
              INT: {data.codigoDeInterno ?? "-"}
            </Text>
          </View>
        </View>

        <View
          flexDirection="column"
          justifyContent="center "
          style={{ marginBottom: "10px" }}
        >
          <Text textAlign="center" fontSize="16px" bold>
            "LOGADEX"
          </Text>
          <Text textAlign="center" fontSize="16px" bold>
            AGENCIA DESPACHANTE DE ADUANAS S.R.L.
          </Text>
        </View>

        <View>
          <View width="30%"></View>
          <View
            width="40%"
            alignItems="center"
            flexDirection="column"
            justifyContent="space-between"
          >
            <Text fontSize="16px" padding="1px 3px" bold>
              LIQUIDACION - PROFORMA
            </Text>
            <View width="95%" border={{ top: true }}></View>
            <View width="95%" border={{ top: true }}></View>
          </View>
        </View>

        <Text style={{ marginTop: "10px" }} fontSize="11px" bold>
          CONSIGNATARIO: {data.company.razonSocial ?? "-"}
        </Text>
        <Text style={{ margin: "0px" }} fontSize="11px" bold>
          ATENCION: {data.atención ?? "-"}
        </Text>
        <Text style={{ marginBottom: "10px" }} fontSize="11px" bold>
          IMPORTACION N°: {data.importacionN_º ?? "-"}
        </Text>
        <View border={{ top: true }}></View>
        <View border={{ top: true }}></View>
        {validateArray(data.detallesDeLaLiquidaciónSubDatas)
          ? data.detallesDeLaLiquidaciónSubDatas.map((item, index) => (
              <View key={index}>
                <View width="35%" flexDirection="column">
                  <Text
                    style={{ margin: "0px" }}
                    fontSize="11px"
                    padding="2px 3px"
                    bold
                  >
                    {item.label}
                  </Text>
                </View>
                <View width="15%" flexDirection="column">
                  <Text fontSize="11px" padding="2px 3px">
                    $us.
                  </Text>
                </View>
                <View width="25%" justifyContent="flex-end">
                  <Text fontSize="11px" padding="2px 3px">
                    {item.description}
                  </Text>
                </View>
                <View
                  width="25%"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Text fontSize="11px" padding="2px 3px">
                    {" "}
                  </Text>
                  <Text fontSize="11px" padding="2px 3px">
                    {item.description2}
                  </Text>
                </View>
              </View>
            ))
          : null}

        <View border={{ top: true }}></View>
        <View border={{ top: true }}>
          <Text
            style={{ marginBottom: "10px", marginTop: "10px" }}
            fontSize="11px"
            padding="2px 3px"
            bold
          >
            DERECHOS Y TRIBUTOS DE IMPORTACIÓN:
          </Text>
        </View>

        {validateArray(data.derechosYTributosDeImportaciónSubDatas)
          ? data.derechosYTributosDeImportaciónSubDatas.map((item, index) => (
              <View key={index}>
                <View width="35%" flexDirection="column">
                  <Text
                    style={{ margin: "0px" }}
                    fontSize="11px"
                    padding="2px 3px"
                    bold
                  >
                    {item.label}
                  </Text>
                </View>
                <View width="15%" flexDirection="column">
                  <Text fontSize="11px" padding="2px 3px">
                    Bs.
                  </Text>
                </View>
                <View width="25%" justifyContent="flex-end">
                  <Text fontSize="11px" padding="2px 3px">
                    {item.description}
                  </Text>
                </View>
                <View width="25%" justifyContent="flex-end" alignItems="center">
                  <Text fontSize="11px" padding="2px 3px">
                    {item.description2}
                  </Text>
                </View>
              </View>
            ))
          : null}
        <View border={{ top: true }}></View>
        <View>
          <View width="35%" flexDirection="column">
            <Text
              style={{ margin: "0px" }}
              fontSize="11px"
              padding="2px 3px"
              bold
            >
              TOTAL
            </Text>
          </View>
          <View width="15%" flexDirection="column">
            <Text fontSize="11px" padding="2px 3px">
              Bs.
            </Text>
          </View>
          <View width="25%" justifyContent="flex-end">
            <Text fontSize="11px" padding="2px 3px">
              {validateArray(data.derechosYTributosDeImportaciónSubDatas)
                ? data.derechosYTributosDeImportaciónSubDatas?.reduce(
                    (total, sum) => {
                      return total + Number(sum.description);
                    },
                    0
                  )
                : 0}
            </Text>
          </View>
          <View
            width="25%"
            justifyContent="flex-end"
            alignItems="center"
          ></View>
        </View>

        <View style={{ marginTop: "20px" }}>
          <View width="35%" flexDirection="column">
            <Text
              style={{ margin: "0px" }}
              fontSize="11px"
              padding="2px 3px"
              bold
            >
              TRIBUTOS FISCALES
            </Text>
          </View>
          <View width="15%" flexDirection="column">
            <Text fontSize="11px" padding="2px 3px">
              Bs.
            </Text>
          </View>
          <View width="25%" justifyContent="flex-end">
            <Text fontSize="11px" padding="2px 3px">
              {validateArray(data.derechosYTributosDeImportaciónSubDatas)
                ? Number(
                    data.derechosYTributosDeImportaciónSubDatas
                      ?.filter(
                        (item) =>
                          item.label === "G.A." ||
                          item.label === "I.V.A." ||
                          item.label === "DIM"
                      )
                      .reduce((total, sum) => {
                        return total + Number(sum.description);
                      }, 0)
                  ).toFixed(2)
                : 0}
            </Text>
          </View>
          <View
            width="25%"
            justifyContent="flex-end"
            alignItems="center"
          ></View>
        </View>

        <View
          borderContent
          flexDirection="column"
          padding="2px"
          border={{ top: true, bottom: true, right: true, left: true }}
          style={{ marginTop: "10px" }}
        >
          <Text textAlign="center" fontSize="10px" bold>
            Corriente No 1-00000 29902140 del Banco Unión S.A. en Bs., a nombre
            de "LOGADEX" AGENCIA
          </Text>
        </View>

        <View
          width="17%"
          flexDirection="column"
          justifyContent="flex-end"
          alignItems="flex-end"
        >
          <Image src={qrLogadex} style={{ width: "70px", marginTop: "10px" }} />
        </View>

        <View
          flexDirection="column"
          padding="10px"
          style={{ marginTop: "30px" }}
        >
          <Text color="#1db963" textAlign="center" fontSize="8px" bold>
            Av. Jaimes Freyre No. 2354 Edificio “Torre Karito” PIso 1 Of. 2 Zona
            Sopocachi
          </Text>
          <Text color="#1db963" textAlign="center" fontSize="8px" bold>
            Telf.: (591)2417290 - (591) 71551797 - (591) 72501500
          </Text>
        </View>
      </Page>
    </Document>
  );

  // async function handleOnClick() {
  //   await pdf(MyDoc)
  //     .toBlob()
  //     .then((r) => {
  //       var file = new File([r], `Proforma-${dayjs().format("DD-MM-YYYY")}`);
  //       uploadFile(file);
  //     });
  // }

  return (
    <div>
      {/* <BlobProvider document={MyDoc}>
        {({ blob }) => {
          return <div onClick={() => uploadFile(blob)}>Subir documento</div>
        }}
      </BlobProvider> */}
      <PDFViewer
        style={{
          width: 900,
          height: window.innerHeight - 100,
        }}
        children={MyDoc}
      />
    </div>
  );
};

export default PdfProform;
