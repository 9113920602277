import dayjs from "dayjs";
import config from "../config.json";
const hostname = window.location.hostname;

export const validateStatus = (state) => {
  const status = [200, 201, 202, 203, 204, 204];
  if (status && status.indexOf(state) > -1) {
    return true;
  }
  return false;
};
export const validateArray = (list) => {
  return list && Array.isArray(list) && list.length > 0;
};

export const validateUrlToOpen = (url, institutionParam) => {
  if (url?.startsWith("/")) {
    if (hostname === config.hostname) {
      if (institutionParam?.find((i) => i.code === "mediaRequest")?.value) {
        return (
          institutionParam?.find((i) => i.code === "mediaRequest")?.value + url
        );
      }
      return url;
    } else {
      if (institutionParam?.find((i) => i.code === "mediaRequest2")?.value) {
        return (
          institutionParam?.find((i) => i.code === "mediaRequest2")?.value + url
        );
      }
      return url;
    }
  }

  return url;
};

export const generateYearArray = () => {
  const currentYear = dayjs().year();
  const currentMonth = dayjs().month();
  const includeNextYear = currentMonth >= 10;

  const years = [];
  for (let year = currentYear; year >= 2023; year--) {
    years.push({
      label: year.toString(),
      name: year.toString(),
      initial: year === currentYear,
    });
  }

  if (includeNextYear) {
    years.unshift({
      label: (currentYear + 1).toString(),
      name: (currentYear + 1).toString(),
    });
  }

  return years;
};
