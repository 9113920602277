import { Route, Routes, Navigate } from "react-router-dom";
import Login from "../pages/auth/login/Login";
import Recover from "../pages/auth/recover/Recover";
import PasswordRecovery from "../pages/auth/password-recovery/PasswordRecovery";
import {
  ManageUsers,
  FolderList,
  StepProcedure,
  ManageCompany,
} from "../pages/menu";
import { validateArray } from "../utils/validate";
import Template from "../template/Template";
import Procedure from "../pages/systems/Procedures";
import Summary from "../pages/Summary/Summary";
import Menus from "../pages/menus/Menus";
import Roles from "../pages/roles/Roles";
import ClientList from "../pages/clientList/ClientList";
import StepsTramites from "../pages/steps/StepsTramites";
import ManegeProcedure from "../pages/manageProcedures/ManegeProcedure";
import Management from "../pages/Management/Management";
import ListProcedures from "../pages/listProcedures/ListProcedures";
import LendingList from "../pages/LendingList/LendingList";
import MyLoans from "../pages/MyLoans/MyLoans";
import Regularization from "../pages/Regularization/Regularization";
// import ListInstitution from "../pages/ListInstitution/ListInstitution";
import History from "../pages/History/History";
import ProcedureTypes from "../pages/ProcedureTypes/ProcedureTypes";
import CompleteDocuments from "../pages/CompleteDocuments/CompleteDocuments";
import GenerateExcel from "../pages/generateExcel/GenerateExcel";
import Settlement from "../pages/Settlement/Settlement";
import DropdownManagement from "../pages/DropdownManagement/DropdownManagement";
import FieldManager from "../pages/FieldManager/FieldManager";
import ListProceduresView from "../pages/ListProceduresView/ListProceduresView";
import ViewStep from "../pages/ViewStep/ViewStep";
import ManageInstitution from "../pages/ManageInstitution/ManageInstitution";
import ManageButtons from "../pages/ManageButtons/ManageButtons";
import ManageAppointments from "../pages/ManageAppointments/ManageAppointments";
import ProceduresInCharge from "../pages/ProceduresInCharge/ProceduresInCharge";
import ProceduresConfirmationFunds from "../pages/ProceduresConfirmationFunds/ProceduresConfirmationFunds";
import ProofDeliveries from "../pages/ProofDeliveries/ProofDeliveries";
import GenerateExcelDav from "../pages/GenerateExcelDav/GenerateExcelDav";
import Fiscalization from "../pages/Fiscalization/Fiscalization";
import ManageExpenses from "../pages/ManageExpenses/ManageExpenses";
import { PaymentProcedure } from "../pages/ManageExpenses";
import DimPayment from "../pages/DimPayment/DimPayment";

const routeAdminUser = [
  {
    path: "manage-users",
    element: <ManageUsers />,
  },
  {
    path: "manage-company",
    element: <ManageCompany />,
  },
  {
    path: "MenuRegister",
    element: <Menus />,
  },
  {
    path: "RolRegister",
    element: <Roles />,
  },
];
const routeClient = [
  {
    path: "ClientSummary",
    element: <Summary />,
  },
  {
    path: "ClientList",
    element: <ClientList />,
  },
];
const routeAdminForms = [
  {
    path: "HandleSteps",
    element: <Procedure />,
  },
  {
    path: "ProcedureTypes",
    element: <ProcedureTypes />,
  },
  {
    path: "dropdown-management",
    element: <DropdownManagement />,
  },
  {
    path: "fieldManager",
    element: <FieldManager />,
  },
  {
    path: "view-step",
    element: <ViewStep />,
  },
  {
    path: "manage-buttons",
    element: <ManageButtons />,
  },
];
const routeSettlement = [
  {
    path: "Settlement",
    element: <Settlement />,
  },
];
const routeManagement = [
  {
    path: "Management",
    element: <Management />,
  },
];
const routeAdminProcedure = [
  {
    path: "ManageProcedures",
    element: <ManegeProcedure />,
  },
  {
    path: "HistoryProcedure",
    element: <ListProcedures />,
  },
  {
    path: "folderList",
    element: <FolderList />,
  },
  {
    path: "folderList/step-procedure/:procedureId",
    element: <StepProcedure />,
  },
  {
    path: "folderList/payment-procedure/:procedureId",
    element: <PaymentProcedure />,
  },
];
const routeLoans = [
  {
    path: "LendingList",
    element: <LendingList />,
  },
  {
    path: "MyLoans",
    element: <MyLoans />,
  },
];
const routeRegularization = [
  // {
  //   path: "regularization-imp",
  //   element: <Regularization type="regularizado" procedureTypeId={1} />,
  // },
  // {
  //   path: "regularization-exp",
  //   element: <Regularization type="regularizado" procedureTypeId={2} />,
  // },
  {
    path: "regularization-anticipado",
    element: <Regularization type="anticipado" procedureTypeId={1} />,
  },
  {
    path: "regularization-normal",
    element: <Regularization type="normal" procedureTypeId={1} />,
  },
  {
    path: "dim-payment",
    element: <DimPayment />,
  },
];

const routeFiscalization = [
  {
    path: "fiscalization-imp",
    element: <Fiscalization type="fiscalización" procedureTypeId={1} />,
  },
  {
    path: "fiscalization-exp",
    element: <Fiscalization type="fiscalización" procedureTypeId={2} />,
  },
];
// const routeInstitution = [
//   {
//     path: "ListInstitution",
//     element: <ListInstitution />,
//   },
// ];
const routeCompleteDocuments = [
  {
    path: "CompleteDocuments",
    element: <CompleteDocuments />,
  },
];
const routeListProcedures = [
  {
    path: "list-procedures",
    element: <ListProceduresView />,
  },
];
const routeManageInstitution = [
  {
    path: "manage-institution",
    element: <ManageInstitution />,
  },
];
const routeCharge = [
  {
    path: "procedures-in-charge",
    element: <ProceduresInCharge />,
  },
];

const routeConfirmationOfFunds = [
  {
    path: "procedures-confirmation-funds",
    element: <ProceduresConfirmationFunds />,
  },
];

const RouteMaster = () => {
  const createRouter = [
    {
      path: "/rol/:rolId/menu",
      index: <Template />,
      children: [
        ...routeAdminUser,
        ...routeClient,
        ...routeAdminForms,
        ...routeManagement,
        ...routeAdminProcedure,
        ...routeLoans,
        ...routeRegularization,
        ...routeFiscalization,
        // ...routeInstitution,
        ...routeCompleteDocuments,
        ...routeSettlement,
        ...routeListProcedures,
        ...routeManageInstitution,
        ...routeCharge,
        ...routeConfirmationOfFunds,
        {
          path: "manage-appointments",
          element: <ManageAppointments />,
        },
        {
          path: "proof-deliveries",
          element: <ProofDeliveries />,
        },
        {
          path: "manage-expenses",
          element: <ManageExpenses />,
        },
        // {
        //   path: "manage-expenses/payment-procedure/:procedureId",
        //   element: <PaymentProcedure />,
        // },
      ],
    },
    {
      path: "rol/:rolId/procedure-type/:typeId/step/",
      index: <Template />,
      children: [
        {
          path: ":step",
          element: <StepsTramites />,
        },
        {
          path: ":step/procedure/:procedureId/generate/dav",
          element: <GenerateExcelDav />,
        },
        {
          path: ":step/procedure/:procedureId/generate/:document",
          element: <GenerateExcel />,
        },
        {
          path: ":step/procedure/:procedureId/generate/:document/review",
          element: <GenerateExcel review={true} />,
        },
        {
          path: ":step/procedure/:procedureId/generate/:document/answer",
          element: <GenerateExcel review={true} />,
        },
      ],
    },
    {
      path: "rol/:rolId/",
      index: <Template />,
      children: [
        {
          path: "history-procedure",
          element: <History />,
        },
      ],
    },
  ];

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/auth/login" />} />
      <Route path="/auth/login" element={<Login />} />
      <Route path="/auth/recover" element={<Recover />} />
      {/*<Route path="/auth/password-recovery/:verifyKey" element={<Login />} />*/}
      <Route
        path="/auth/password-recovery/:verifyKey"
        element={<PasswordRecovery />}
      />
      {createRouter.map((route, index) => (
        <Route key={index} path={route.path}>
          {route.index && <Route index element={route.index} />}
          {validateArray(route.children)
            ? route.children.map((children, j) => (
                <Route
                  key={j}
                  path={children.path}
                  element={
                    <Template path={children.path} main={children.element} />
                  }
                />
              ))
            : null}
          <Route path="*" element={<></>} />
        </Route>
      ))}
    </Routes>
  );
};
export default RouteMaster;
